import { Box } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import PreviewCartProduct from 'components/PreviewCartProduct';

import { getCartRoute } from 'hooks/useRouting';

import { ProductInfo } from 'types/cart.interface';

interface PreviewCartContentProps {
  products: ProductInfo[];
  closeCart(): void;
}

const PreviewCartContent:FC<PreviewCartContentProps> = ({ products, closeCart }) => {
  const navigate = useNavigate();

  const onGoToCheckout = () => {
    closeCart();
    navigate(getCartRoute());
  };

  return (
    <Box p={1.5}>
      <Box maxHeight="240px" className="hidden-scroll">
        {products.map((product) => (
          <PreviewCartProduct
            product={product}
            key={product?.id}
            descriptionLength={50}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1.5}>
        <CustomButton
          id="button-viewCart"
          variant="contained"
          color="primary"
          onClick={onGoToCheckout}
        >
          View cart
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PreviewCartContent;
