import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

import { formatProductWeight } from 'utils/productUtils';

interface ProductWeightProps {
  weight: number;
  fontWeight: number;
  variant: Variant;
}

const ProductWeight :FC<ProductWeightProps> = ({ weight, fontWeight, variant }) => (
  <Typography variant={variant} fontWeight={fontWeight}>
    {formatProductWeight(weight)}
    g
  </Typography>
);

export default ProductWeight;
