import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from 'date-fns';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import BackButton from 'components/BackButton';
import CartTotal from 'components/CartTotal';
import Loader from 'components/Loader';
import OrderContactInfo from 'components/OrderContactInfo';
import OrderPaymentInfo from 'components/OrderPaymentInfo';
import OrderProductPreview from 'components/OrderProductPreview';
import OrderTypeInfo from 'components/OrderTypeInfo';
import QrCodeWrapper from 'components/QrCodeWrapper';
import SectionTitle from 'components/SectionTitle';
import SubheaderWrapper from 'components/SubheaderWrapper';
import { SystemContext } from 'context/SystemContext';
import { UserContext } from 'context/UserContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useReceipt } from 'hooks/useReceipt';
import { getDigitalReceiptRoute, getOrderRoute, useOrderId } from 'hooks/useRouting';
import { cancelOrder, getOrderDetails } from 'services/Order';

import { DeliveryMethod, OrderStatus, ReceiptStatus } from 'constants/enums';
import { EXTENDED_ORDER_DATE_FORMAT } from 'constants/general';
import { ORDER_STATUS } from 'constants/statuses';
import { Bounty } from 'types/bounty.interface';
import { User } from 'types/user.interface';
import { getCardDetails, isPickupMethod } from 'utils/checkoutUtils';
import { formatReward } from 'utils/currencyUtils';
import { handleApiErrors } from 'utils/errorUtils';
import { isOrderCancellable } from 'utils/orderUtils';
import { getLocalUsStates } from 'utils/storageUtils';

import {
  GeneralDetails, StyledBox, StyledStatusWrapper,
} from './index.styled';

export default function OrderDetailsPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [bounty, setBounty] = useState<Bounty|null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isCancelConfirmationOpen, setOpenCancelConfirmation] = useState(false);
  const orderId = useOrderId();
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);
  const { shopSettings, locationOptions, clientInfo } = useContext(SystemContext);

  const { order } = bounty || {} as Bounty;
  const merchant = order?.productsInfo?.products
    ? Object.values(order?.productsInfo?.products)?.[0]?.merchant
    : {} as User;

  const usStates = getLocalUsStates();
  const state = useMemo(() => (
    usStates?.find((states) => states?.key === order?.customerInfo?.customer?.address?.state)?.value
  ), [usStates, order]);
  const supportNumber = shopSettings?.supportPhoneNumber;
  const isCancellable = useMemo(
    () => (order ? isOrderCancellable(order) : false),
    [order?.status],
  );
  const locationInfo = useMemo(() => (
    locationOptions.list.find(({ id }) => id === clientInfo?.id)
  ), [clientInfo, locationOptions]);
  const delivery = order?.customerInfo?.delivery;

  const { receiptStatus } = useReceipt(order?.orderId, delivery?.method);
  const cartDetails = getCardDetails(order?.invoiceInfo?.summary?.sections?.IN_APP);

  // TODO: check this later
  // const cartPriceDetails = useMemo(() => ({
  //   subTotal: formatReward(order?.negotiatedAmount, '0', false),
  //   formattedTotal: formatReward(order?.orderTotal, '0', false),
  // }), [order]);

  useDocumentTitle({ title: 'Order Details' });

  useEffect(() => {
    if (orderId) {
      fetchOrder(orderId);
    }
  }, [orderId]);

  const fetchOrder = async (id: string) => {
    setIsLoading(true);
    try {
      const payload = { userId: user.uid, orderId: id };
      const response = await getOrderDetails(payload);
      setBounty(response.data);
    } catch (error) {
      handleApiErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = async () => {
    if (!bounty || !order || !order?.orderId) {
      toast.error('Missing information order id!');
      return;
    }

    try {
      setIsCanceling(true);
      await cancelOrder(order.orderId);
      const newBounty = {
        ...bounty,
        order: {
          ...bounty.order,
          status: OrderStatus.CANCELLED,
        },
        updatedAt: new Date().getTime(),
      } as Bounty;
      setBounty(newBounty);
      setOpenCancelConfirmation(false);
      toast.success('Your order has been cancelled!');
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsCanceling(false);
    }
  };

  const handleOpenCancelModal = () => {
    setOpenCancelConfirmation(true);
  };

  const handleCloseCancelModal = () => setOpenCancelConfirmation(false);

  const handleRedirectToReceipt = () => (orderId ? navigate(getDigitalReceiptRoute(orderId)) : null);

  useEffect(() => {
    if (receiptStatus === ReceiptStatus.Scanned) {
      handleRedirectToReceipt();
      toast.success('Digital receipt sent to your mobile device.');
    }
  }, [receiptStatus]);

  return (
    <>
      <SubheaderWrapper>
        <Box display="flex" alignItems="center">
          <BackButton href={getOrderRoute()} label="Back" />
          <SectionTitle
            variant="subheader"
            title={`Order ${order?.orderId ? `# ${order.orderId}` : ''}`}
            sx={{ ml: 2.5 }}
          />
        </Box>
      </SubheaderWrapper>

      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          {!!supportNumber && (
            <Typography variant="body2" mb={2} textAlign="end" mr={3}>
              {`If you need to change your order, call this number: ${supportNumber}`}
            </Typography>
          )}

          {delivery?.method === DeliveryMethod.PICKUP && order?.receiptUrl && (
            <QrCodeWrapper
              size={matchesScreenSizeSm ? 50 : 75}
              src={order?.receiptUrl}
              sx={{ mb: 2, p: 2, borderRadius: '4px' }}
              innerSx={{ display: 'flex', alignItems: 'center', gap: 2 }}
              backgroundColor="general.lightGrey6"
              isMobileView={matchesScreenSizeSm}
              onRedirect={handleRedirectToReceipt}
            >
              <Typography variant="body2">
                {matchesScreenSizeSm
                  ? 'View digital receipt.'
                  : 'Scan this QR Code with your phone to receive your digital receipt.'}
                <br />
                <b>
                  {matchesScreenSizeSm
                    ? 'At pickup, please show it to the vendor'
                    : 'At pickup, please show the resulting page to the vendor.'}
                </b>
              </Typography>
            </QrCodeWrapper>
          )}

          <GeneralDetails sx={{ clear: 'both' }}>
            <div>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                  From:
                </Typography>

                <Typography variant="body2" fontWeight={600} ml={4}>
                  {format(order?.orderedAt || Date.now(), EXTENDED_ORDER_DATE_FORMAT)}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center">
                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                  Total:
                </Typography>

                <Typography variant="body2" fontWeight={600} ml={4}>
                  {formatReward(order?.orderTotal, '0', true)}
                </Typography>
              </Box>
            </div>

            {isCancellable && (
              <div>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleOpenCancelModal}
                  disabled={isCanceling}
                >
                  Cancel
                </Button>
              </div>
            )}
          </GeneralDetails>

          {merchant?.name && (
            <Typography variant="body2" fontWeight={600} mt={4} ml={2} mb={2}>
              {`Sold by ${merchant.name}`}
            </Typography>
          )}

          <StyledStatusWrapper status={order?.status as OrderStatus} mt={merchant?.name ? 0 : 2}>
            {order?.status && (
              <Typography variant={isCancellable ? 'subheader' : 'body2'}>
                {`[${ORDER_STATUS[order?.status]}]`}
              </Typography>
            )}

            <Typography variant={isCancellable ? 'subheader' : 'body2'} ml={order?.status ? 3 : 0}>
              From date:
            </Typography>

            <Typography variant={isCancellable ? 'subheader' : 'body2'} ml={0.5} fontWeight={600}>
              {format(bounty?.updatedAt || Date.now(), EXTENDED_ORDER_DATE_FORMAT)}
            </Typography>
          </StyledStatusWrapper>

          {order && (
            <Grid container spacing={2} mt={2} mb={4}>
              <Grid item xs={12} sm={4}>
                <StyledBox minHeight="325px">
                  <OrderContactInfo customer={order?.customerInfo?.customer} state={state} />
                </StyledBox>
              </Grid>

              <Grid item xs={12} sm={4}>
                <StyledBox minHeight="325px">
                  <OrderTypeInfo
                    delivery={order?.customerInfo?.delivery}
                    address={isPickupMethod(order?.customerInfo?.delivery?.method)
                      ? locationInfo?.address
                      : order?.shippingAddress}
                  />
                </StyledBox>
              </Grid>

              <Grid item xs={12} sm={4}>
                <StyledBox minHeight="325px">
                  <OrderPaymentInfo order={order} cartDetails={cartDetails} />
                </StyledBox>
              </Grid>
            </Grid>
          )}

          {order?.productsInfo?.products && Object.values(order?.productsInfo?.products)?.map((product) => (
            <Box width="100%" key={product?.id}>
              <OrderProductPreview product={product} />
              <Divider sx={{ mt: 1, mb: 3.5 }} />
            </Box>
          ))}

          <Box width="100%" display="flex" justifyContent="flex-end" my={1}>
            <CartTotal cartPriceDetails={cartDetails} sx={{ minWidth: '175px', opacity: 0.7 }} />
          </Box>
        </Container>
      )}
      {isCancelConfirmationOpen && (
        <Dialog
          open={isCancelConfirmationOpen}
          onClose={handleCloseCancelModal}
          aria-describedby="cancel-order"
        >
          <DialogTitle>
            Cancel Order
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to cancel this order?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: '0 24px 24px' }}>
            <Button
              variant="outlined"
              onClick={handleCloseCancelModal}
            >
              Cancel
            </Button>
            <Button
              disabled={isCanceling}
              variant="contained"
              onClick={handleCancel}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
