import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import BackCard from 'components/BackCard';
import { CategoryCard, SelectedCategoryCard } from 'containers/Categories/KioskCategories/index.styled';

import { PRODUCTS_ROUTE } from 'constants/clientRoutes';
import { Category } from 'types/category.interface';

interface KioskCategoriesProps {
  selectors: Category[][];
  selectedCategories: string[];
  onCategoryChange: (category: string, index: number) => void;
}

const KioskCategories:FC<KioskCategoriesProps> = ({ selectors, selectedCategories, onCategoryChange }) => {
  const navigate = useNavigate();
  const visibleSelectedCategories = selectedCategories.filter((category) => category !== 'all');
  const selectorIndex = selectors.length - 1;
  const currentSelector = selectorIndex === visibleSelectedCategories.length - 1 ? [] : selectors[selectorIndex];

  const findName = (key: string, index: number) => {
    const list = selectors[index];
    const details = list.find(({ code }) => code === key);
    return details?.name || key;
  };

  const seeAll = () => {
    navigate({ pathname: PRODUCTS_ROUTE, search: '?category=all' });
  };

  const removeCategory = (category: string) => {
    let newCategories = [...selectedCategories];
    const position = newCategories.findIndex((cat) => cat === category);

    if (position > -1) {
      newCategories = newCategories.slice(0, position);
      navigate({ pathname: PRODUCTS_ROUTE, search: `?category=${newCategories.join(',')}` });
    }
  };

  return (
    <div>
      {visibleSelectedCategories.length > 0 && (
        <BackCard role="presentation" onClick={seeAll} text="All Categories" />
      )}
      {visibleSelectedCategories.map((category, index) => (
        <SelectedCategoryCard key={category}>
          <Typography fontWeight={700}>
            {findName(category, index)}
          </Typography>
          {index > 0 && (
            <IconButton onClick={() => removeCategory(category)}>
              <CloseIcon />
            </IconButton>
          )}
        </SelectedCategoryCard>
      ))}
      {currentSelector.map(({ code, name }) => (
        <CategoryCard key={code} onClick={() => onCategoryChange(code, selectorIndex)}>
          <Typography fontWeight={700}>
            {name}
          </Typography>
        </CategoryCard>
      ))}
    </div>
  );
};

export default KioskCategories;
