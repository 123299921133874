import {createTheme} from '@mui/material/styles';

import palette from 'assets/themes/palettes/defaultPalette';
import { kioskVariants, variants } from "assets/themes/variants";
import { getShadowDomRoot } from "utils/utils";

const node = getShadowDomRoot();

const getAppTheme = (customPalette: CustomPaletteProps = {} as CustomPaletteProps, kioskMode?: boolean) => {
  const theme = createTheme({
    palette: {
      ...palette,
      ...customPalette,
    },
    typography: {
      ...(kioskMode ? kioskVariants : variants),
      button: {
        textTransform: 'none',
        ...(kioskMode ? kioskVariants.button : variants.button),
      },
    },
  });

  return {
    ...theme,
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 40px'
          }
        },
        defaultProps: {
          container: node,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: node,
        },
      },
      MuiModal: {
        defaultProps: {
          container: node,
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: kioskMode ? '1.2rem' : '1rem',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: kioskMode ? '1.8rem' : '1.5rem',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: kioskMode ? '10px' : '8px',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: kioskMode ? '1.2rem' : '1rem',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.common.white,
            borderRadius: kioskMode ? '8px': '4px',

            '& .MuiSelect-select': {
              padding: kioskMode ? '15px 14px' : '8.5px 14px',
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: kioskMode ? '8px': '4px',
          },
          notchedOutline: {
            borderColor: theme.palette.border.main,

            '& legend': {
              lineHeight: '10px',
            },
          },
          input: {
            fontSize: kioskMode ? '1.2rem' : '1rem',
            height: kioskMode ? '40.6px': '23px',
          }
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: theme.palette.error.main,
          },
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            borderRadius: '8px',
            '&:hover': {
              boxShadow: 'none',
            },

            '&:disabled': {
              opacity: 0.35,
            },
          },
          containedPrimary: {
            '&:disabled': {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
          },
          containedSecondary: {
            backgroundColor: theme.palette.secondary.main,

            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
            },
          },
          outlinedPrimary: {
            borderColor: theme.palette.primary.main,

            '&:hover': {
              borderColor: theme.palette.primary.main,
            },
          },
          outlinedSecondary: {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.main,

            '&:hover': {
              borderColor: theme.palette.secondary.main,
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            '@media (min-width: 600px)': {
              width: '90%',
            }
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: palette.input.border,
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderColor: theme.palette.border.main,
            color: palette.text.secondary,
          },
          sizeMedium: {
            height: kioskMode ? '50px' : '35px',
            fontSize: kioskMode ? '1.3rem' : '1rem',
          }
        }
      },
      MuiDialog: {
        defaultProps: {
          container: node,
        },
        styleOverrides: {
          paper: {
            [theme.breakpoints.down('sm')]: {
              maxHeight: '100%',
              maxWidth: '100%',
              height: '100%',
              width: '100%',
              margin: 0,
              borderRadius: 0,
            }
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '1.5rem',
          }
        }
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'subheader' },
            style: {
              color: theme.palette.getContrastText(theme.palette.subheaderBackground.main),
              ...theme.typography.body2,
            }
          }
        ]
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            color: theme.palette.getContrastText(theme.palette.subheaderBackground.main),
          }
        }
      },
    },
  }
};

export default getAppTheme;


declare module '@mui/material/styles' {
  interface Palette {
    border: Palette['primary'];
    avatar: Palette['primary'];
    subheaderBackground: Palette['primary'];
    link: Palette['primary'];
    headerBackground: Palette['primary'];
    input: Palette['primary'];
    general: Palette['primary'];
  }

  interface PaletteColor {
    lightGrey: string;
    lightGrey1: string;
    lightGrey2: string;
    lightGrey3: string;
    lightGrey4: string;
    lightGrey5: string;
    lightGrey6: string;
    lightGrey7: string;
    darkYellow1: string;
    lightYellow1: string;
    lightYellow2: string;
    darkGreen1: string;
    lightGreen: string;
    lightGreen1: string;
    lightBlue: string;
    lightBlue1: string;
    lightOrange: string;
    messageBubble: string;
    alleavesBackground: string;
  }

  interface TypeBackground {
    main: string,
    secondary: string,
  }
}

export interface CustomPaletteProps {
  primary: {
    main: string;
    secondary: string;
    light: string;
  },
  secondary: {
    main: string;
    secondary: string;
    light: string;
  },
  headerBackground: {
    main: string;
  },
  subheaderBackground: {
    main: string;
  },
}


declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subheader: true;
    body3: true;
  }
}

export type CustomTypographyVariant = 'subheader';
