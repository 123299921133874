import { Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  FC, useContext, useEffect, useMemo, useState,
} from 'react';
import { forceCheck } from 'react-lazyload';
import { useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import CarouselNextArrow from 'components/CarouselNextArrow';
import CarouselPrevArrow from 'components/CarouselPrevArrow';
import ProductCard from 'components/ProductCard';
import SectionTitle from 'components/SectionTitle';
import UnstyledLink from 'components/UnstyledLink';
import CategoryCarouselSkeleton from 'containers/CategoryCarousel/index.skeleton';
import { StyledViewAll } from 'containers/CategoryCarousel/index.styled';
import ViewAllCard from 'containers/CategoryCarousel/ViewAllCard';
import { CartDispatchContext } from 'context/CartContext';
import { CategoryDispatchContext } from 'context/CategoryContext';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { getProductDetailsRoute, getProductsRoute } from 'hooks/useRouting';
import { getProducts } from 'services/Product';

import { ViewMode } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { Category } from 'types/category.interface';
import { getCategoriesCarouselSettings } from 'utils/carousel';
import { formatSearchParams } from 'utils/formatters';

interface CategoryCarouselProps {
  category: Category;
}

const CategoryCarousel:FC<CategoryCarouselProps> = ({ category }) => {
  const [products, setProducts] = useState<Bounty[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { shopSettings } = useContext(SystemContext);
  const { addItem } = useContext(CartDispatchContext);
  const { removeCategory } = useContext(CategoryDispatchContext);
  const { onboardingInfo } = useContext(LocationContext);

  const { handleTrackViewItems, handleTrackAddItemToCart, handleTrackViewItem } = useGoogleAnalytics();
  const [searchParams] = useSearchParams();
  const { kioskMode } = useKiosk();
  const theme = useTheme();
  const matchesScreenSizeLg = useMediaQuery(theme.breakpoints.only('lg'));
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.only('md'));
  const matchesScreenSizeXl = useMediaQuery(theme.breakpoints.only('xl'));
  const routeWithCategory = getProductsRoute(`category=${category.code}`);

  const showMoreCard = useMemo(() => {
    if (!matchesScreenSizeXl && !matchesScreenSizeLg && !matchesScreenSizeMd) {
      return true;
    }

    if (matchesScreenSizeXl && products.length > 4) {
      return true;
    }

    if (matchesScreenSizeLg && products.length > 3) {
      return true;
    }

    return matchesScreenSizeMd && products.length > 2;
  }, [products, matchesScreenSizeLg, matchesScreenSizeMd, matchesScreenSizeXl]);

  const { storeId, useType } = onboardingInfo;

  const settings = {
    ...getCategoriesCarouselSettings(theme),
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  useEffect(() => {
    fetchProducts();
  }, [storeId, useType]);

  useEffect(() => {
    if (products.length > 0) {
      handleTrackViewItems(category, products);
    }
  }, [products]);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);

      const params = formatSearchParams(searchParams);
      params.set('category', category.code);

      const { data } = await getProducts(params);

      if (!data?.list?.length) {
        handleEmptyProductsList();
      } else {
        setProducts(data?.list || []);
      }
    } catch (e) {
      handleEmptyProductsList();
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmptyProductsList = () => {
    removeCategory(category.code);
    setTimeout(() => forceCheck());
  };

  const addToCard = (bounty: Bounty) => {
    addItem(bounty, 1);
    handleTrackViewItem(bounty);
    handleTrackAddItemToCart(bounty, 1);
  };

  if (isLoading) {
    return <CategoryCarouselSkeleton />;
  }

  return (
    <Box mb={10}>
      <Box display="flex" alignItems="center" justifyContent="space-between" pr={1.5}>
        <SectionTitle title={category.name} variant="h5" sx={{ mb: 2 }} />
        <UnstyledLink to={routeWithCategory}>
          <StyledViewAll>
            View All
          </StyledViewAll>
        </UnstyledLink>
      </Box>
      <Slider {...settings}>
        {products?.map((bounty) => (
          <UnstyledLink
            key={bounty.id}
            to={getProductDetailsRoute(bounty.shortId)}
            height="100%"
            width="100%"
          >
            <ProductCard
              bounty={bounty}
              onAdd={addToCard}
              hasAddButton={!kioskMode}
              isDescriptionVisible={shopSettings?.viewConfigs?.PRODUCT_DESCRIPTION?.viewMode === ViewMode.Full}
            />
          </UnstyledLink>
        ))}
        {showMoreCard && (
          <UnstyledLink
            to={routeWithCategory}
            height="100%"
            width="100%"
          >
            <ViewAllCard />
          </UnstyledLink>
        )}
      </Slider>
    </Box>
  );
};

export default CategoryCarousel;
