import {
  createContext, FC, ReactNode, useContext, useEffect, useState,
} from 'react';

import Loader from 'components/Loader';
import { SystemContext, SystemDispatchContext } from 'context/SystemContext';
import OnboardingLayout from 'layouts/OnboardingLayout';

import { getShopSettings } from 'services/Client';

import { DeliveryMethod } from 'constants/enums';
import { ApiLocation } from 'types/companyLocations.interface';
import { OnboardingInfo } from 'types/onboarding.interface';
import { ShopSettings } from 'types/shopSettings.interface';
import {
  getCompanyLocationsData,
  getOnboardingInfo,
  saveOnboardingInfoToStorage,
  saveShopSettingsToStorage,
} from 'utils/storageUtils';
import { isSetupCompleted } from 'utils/storeUtils';

const LocationContext = createContext({
  onboardingInfo: {} as OnboardingInfo,
  setupCompleted: false,
});

const LocationContextDispatchContext = createContext({
  saveShopSettings: (shopSettings: ShopSettings) => {}, // eslint-disable-line
  saveOnboardingInfo: (onboardingInfo: OnboardingInfo) => {}, // eslint-disable-line
  setSetupCompleted: (value: boolean) => {}, // eslint-disable-line
});

interface LocationContextProviderProps {
  children: ReactNode;
}

const LocationContextProvider:FC<LocationContextProviderProps> = ({ children }) => {
  const { shopSettings, isClosed, clientInfo } = useContext(SystemContext);

  const { setShopSettings, refreshClientInfo } = useContext(SystemDispatchContext);

  const [isLoading, setIsLoading] = useState(true);
  const [onboardingInfo, setOnboardingInfo] = useState<OnboardingInfo>({} as OnboardingInfo);
  const [setupCompleted, setSetupCompleted] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      const savedOnboardingInfo = getOnboardingInfo();
      const { list } = getCompanyLocationsData() || {};

      if (savedOnboardingInfo?.storeId === clientInfo.id && isSetupCompleted(shopSettings)) {
        setSetupCompleted(true);
        setOnboardingInfo(savedOnboardingInfo);
      } else if (list && list?.length === 1) {
        await prepareShopSettings(list[0]);
      } else if (clientInfo.parentId) {
        const store = list?.find(({ id }) => id === clientInfo.id);

        if (store) {
          await prepareShopSettings(store);
        } else {
          setSetupCompleted(false);
        }
      } else {
        setSetupCompleted(false);
      }

      setIsLoading(false);
    };

    if (isClosed) {
      setSetupCompleted(true);
      setIsLoading(false);
    } else {
      initialize();
    }
  }, []);

  const prepareShopSettings = async (store: ApiLocation) => {
    const data = {
      store,
      deliveryMethod: store.pickup ? DeliveryMethod.PICKUP : DeliveryMethod.DELIVERY,
      state: store?.address?.state,
      storeId: store.id,
      storeName: store.name,
      companyId: store.companyId,
      storeSlug: '',
      useType: store.adultUse ? 'is_adult_use' : 'is_medical_use',
      age: 0,
    };

    const { data: locationSettings } = await getShopSettings(store?.companyId);

    saveShopSettings(locationSettings);

    if (locationSettings.filterByAge) {
      saveOnboardingInfo(data);
      setSetupCompleted(false);
    } else {
      await selectLocation(data);
    }
  };

  const selectLocation = async (setup: OnboardingInfo) => {
    try {
      const clientInfo = await refreshClientInfo(setup.storeId);

      const data = {
        ...setup,
        storeSlug: clientInfo?.slug,
      };

      saveOnboardingInfo(data);
      setSetupCompleted(true);
    } catch (e) {
      // if the setup save fails, trigger onboarding flow
      setSetupCompleted(false);
    }
  };

  const saveShopSettings = (shopSettings: ShopSettings): void => {
    setShopSettings(shopSettings);
    saveShopSettingsToStorage(shopSettings);
  };

  const saveOnboardingInfo = (onboardingInfo: OnboardingInfo): void => {
    setOnboardingInfo(onboardingInfo);
    saveOnboardingInfoToStorage(onboardingInfo);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LocationContext.Provider value={{
      onboardingInfo,
      setupCompleted,
    }}
    >
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <LocationContextDispatchContext.Provider value={{
        saveShopSettings,
        setSetupCompleted,
        saveOnboardingInfo,
      }}
      >
        {
          setupCompleted
            ? children
            : <OnboardingLayout />
        }
      </LocationContextDispatchContext.Provider>
    </LocationContext.Provider>
  );
};

export {
  LocationContextProvider,
  LocationContext,
  LocationContextDispatchContext,
};
