import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';

import { generateEmptyArray } from 'utils/productUtils';

interface QuantitySelectorProps {
  selectedQuantity: number;
  handleQuantity: (value: number) => void;
  availableQuantity?: number;
  allowZero?: boolean;
  disabled?: boolean;
  checkIfOptionIsDisabled?: (option: number) => boolean;
}

const QuantitySelector:FC<QuantitySelectorProps> = ({
  selectedQuantity, handleQuantity, availableQuantity, allowZero, checkIfOptionIsDisabled, disabled = false,
}) => {
  const quantity = availableQuantity || 0;
  const options = generateEmptyArray(Math.min(quantity, 100), allowZero);

  const handleChange = ({ target: { value } }: SelectChangeEvent) => {
    handleQuantity(+value);
  };

  return (
    <Select
      size="small"
      value={selectedQuantity.toString()}
      onChange={handleChange}
      disabled={disabled || availableQuantity === 1}
      sx={{ fontSize: '1rem' }}
      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
    >
      {options.length && options.map((option) => (
        <MenuItem
          value={option}
          key={`menu-item-${option}`}
          disabled={checkIfOptionIsDisabled ? checkIfOptionIsDisabled(option) : false}
        >
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default QuantitySelector;
