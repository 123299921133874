import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

interface PriceProps {
  price?: string;
  fontWeight: number;
  variant: Variant;
}

const Price :FC<PriceProps> = ({ price = '--', fontWeight, variant }) => (
  <Typography variant={variant} fontWeight={fontWeight}>
    {price}
  </Typography>
);

export default Price;
