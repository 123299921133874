import { Typography } from '@mui/material';
import { FC } from 'react';

import { QuantitySelector } from './index.styled';

interface QuantityButtonsSelectorProps {
  onDecrease: () => void;
  onIncrease: () => void;
  quantity: number;
}

const QuantityButtonsSelector: FC<QuantityButtonsSelectorProps> = ({
  onDecrease,
  onIncrease,
  quantity,
}) => (
  <QuantitySelector>
    <button
      type="button"
      className="decrease"
      onClick={onDecrease}
    >
      -
    </button>
    <Typography>
      {quantity}
    </Typography>
    <button
      type="button"
      className="increase"
      onClick={onIncrease}
    >
      +
    </button>
  </QuantitySelector>
);

export default QuantityButtonsSelector;
