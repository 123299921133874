import { DeliveryMethod, PaymentMethod, PeriodType } from 'constants/enums';

export const ORDER_DELIVERY_METHOD = {
  [DeliveryMethod.DELIVERY]: 'Delivery',
  [DeliveryMethod.PICKUP]: 'In Store Pickup',
  [DeliveryMethod.RETAIL]: 'Retail',
  [DeliveryMethod.KIOSK]: 'Kiosk',
  [DeliveryMethod.CURBSIDE]: 'Curbside Pickup',
};

export const PAYMENT_METHOD_LABELS = {
  [PaymentMethod.CASH]: 'Cash',
  [`${PaymentMethod.CASH}_SANDBOX`]: 'Cash - Sandbox',
  [PaymentMethod.BILLMYBANK]: 'Bill My Bank',
  [`${PaymentMethod.BILLMYBANK}_SANDBOX`]: 'Bill My Bank - Sandbox',
  [PaymentMethod.DEBIT]: 'Debit',
  [`${PaymentMethod.DEBIT}_SANDBOX`]: 'Debit - Sandbox',
  [PaymentMethod.CHARGEE]: 'Chargee',
  [`${PaymentMethod.CHARGEE}_SANDBOX`]: 'Chargee - Sandbox',
};

export const BALANCE_PERIOD_LABELS = {
  [PeriodType.Daily]: 'daily',
  [PeriodType.Weekly]: 'weekly',
  [PeriodType.Monthly]: 'monthly',
  [PeriodType.Yearly]: 'yearly',
  [PeriodType.Last7d]: 'last 7 days',
  [PeriodType.Last30d]: 'last 30 days',
  [PeriodType.OneTime]: 'one time',
};
