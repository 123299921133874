import {
  Typography, Box, DialogContent, DialogActions, Dialog,
} from '@mui/material';
import { FC } from 'react';

import CustomButton from 'components/CustomButton';

interface RemoveProductConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const RemoveProductConfirmation:FC<RemoveProductConfirmationProps> = ({ isOpen, onClose, onSubmit }) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    aria-describedby="remove-product"
  >
    <DialogContent>
      <Box mb={2} mt={3}>
        <Typography>
          You are about to remove this item from your cart?
        </Typography>
      </Box>
    </DialogContent>
    <DialogActions sx={{ p: '0 24px 24px' }}>
      <CustomButton
        id="button-cancelRemoveProduct"
        variant="outlined"
        onClick={onClose}
      >
        Cancel
      </CustomButton>
      <CustomButton
        id="button-confirmRemoveProduct"
        variant="contained"
        onClick={onSubmit}
      >
        Confirm
      </CustomButton>
    </DialogActions>
  </Dialog>
);

export default RemoveProductConfirmation;
