import { styled } from '@mui/material/styles';

export const QuantitySelector = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  display: 'flex',
  textAlign: 'center',
  height: '60px',

  '& button': {
    width: '60px',
    padding: theme.spacing(1),
    cursor: 'pointer',
    border: 'none',
    fontSize: '1.5rem',
  },

  '& .decrease': {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    backgroundColor: theme.palette.general.lightGrey5,
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },

  '& .increase': {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    backgroundColor: theme.palette.general.lightGrey5,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
  },

  '& .MuiTypography-root': {
    flexGrow: 1,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
