import { Grid, Box, Container } from '@mui/material';
import { onValue, query } from 'firebase/database';
import {
  FC, useEffect, useMemo, useState,
} from 'react';

import BreadcrumbsNavigation from 'components/BreadcrumbsNavigation';
import ProductInformation from 'components/ProductInformation';
import ProductPictures from 'components/ProductPictures';
import SubheaderWrapper from 'components/SubheaderWrapper';
import PreviewProductPictures from 'containers/PreviewProductPictures';
import ProductDetailsSkeleton from 'containers/ProductDetailsPage/ProductDetails/index.skeleton';
import ProductQuantitySelector from 'containers/ProductDetailsPage/ProductQuantitySelector';
import ProductReviews from 'containers/ProductReviews';

import { useBreadcrumbs } from 'hooks/useBreadcrumbs';
import { getBountyCommentsRef } from 'services/Comment';

import { Bounty } from 'types/bounty.interface';
import { Comment } from 'types/comment.interface';
import { filterProductPicture } from 'utils/attachmentsUtils';
import { isTestChannel } from 'utils/utils';

interface ProductDetailsProps {
  bounty: Bounty;
  isLoading: boolean;
}

const ProductDetails:FC<ProductDetailsProps> = ({ bounty, isLoading }) => {
  const [openPreview, setOpenPreview] = useState(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [visiblePictureIndex, setVisiblePictureIndex] = useState(0);

  const { getProductDetailsBreadcrumbs } = useBreadcrumbs();

  const { product, bountyCategories } = bounty;
  const { allPictures } = filterProductPicture(bounty);
  const hasPictures = allPictures?.length > 0;

  const breadcrumbs = useMemo(
    () => getProductDetailsBreadcrumbs({ productName: product?.name, bountyCategories }),
    [product?.name],
  );

  const handleClickPicture = (index: number) => {
    if (index) {
      setVisiblePictureIndex(index);
    }
    setOpenPreview(true);
  };

  useEffect(() => {
    if (bounty?.id) {
      getBountyComments();
    }
  }, [bounty?.id]);

  const getBountyComments = () => {
    const dbQuery = query(getBountyCommentsRef(bounty?.id));

    onValue(dbQuery, (snapshot) => {
      const data = snapshot.val() as Comment[];
      setComments(data || []);
    });
  };

  return (
    <Box>
      <SubheaderWrapper>
        <BreadcrumbsNavigation breadcrumbs={breadcrumbs} />
      </SubheaderWrapper>
      <Container>
        {isLoading
          ? <ProductDetailsSkeleton />
          : (
            <Grid container spacing={2} my={2}>
              <Grid item xs={12} md={6}>
                <ProductPictures
                  product={product}
                  onClick={handleClickPicture}
                  attachments={bounty.attachments}
                  setVisiblePictureIndex={setVisiblePictureIndex}
                  visiblePictureIndex={visiblePictureIndex}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ProductInformation bounty={bounty} />
                <ProductQuantitySelector bounty={bounty} />
              </Grid>
            </Grid>
          )}
        {isTestChannel() && (
          <ProductReviews reviews={comments} bounty={bounty} />
        )}
      </Container>
      {(openPreview && hasPictures) && (
        <PreviewProductPictures
          pictures={allPictures}
          open={openPreview}
          setOpen={setOpenPreview}
          visiblePictureIndex={visiblePictureIndex}
        />
      )}
    </Box>
  );
};

export default ProductDetails;
