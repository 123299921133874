import { isEmpty } from 'lodash';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Helmet } from 'react-helmet';

import KioskProductDetails from 'containers/ProductDetailsPage/KioskProductDetails';
import ProductDetails from 'containers/ProductDetailsPage/ProductDetails';
import { LocationContext } from 'context/LocationContext';
import { SystemContext } from 'context/SystemContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { useProductId } from 'hooks/useRouting';
import { getProductDetails } from 'services/Product';

import { SEOType } from 'constants/enums';
import { Bounty } from 'types/bounty.interface';
import { getFormattedSeoInfo } from 'utils/seoUtils';

const ProductDetailsPage = () => {
  const productId = useProductId();
  const { shopSettings, clientInfo } = useContext(SystemContext);
  const { onboardingInfo } = useContext(LocationContext);

  const [bounty, setBounty] = useState<Bounty>({} as Bounty);
  const [isLoading, setIsLoading] = useState(true);
  const { kioskMode } = useKiosk();

  const { handleTrackViewItem } = useGoogleAnalytics();

  const { product } = bounty;

  useEffect(() => {
    if (productId) {
      fetchProductDetails(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (!isEmpty(product)) {
      handleTrackViewItem(bounty);
    }
  }, [product]);

  useDocumentTitle({ title: 'Product Details' });

  const fetchProductDetails = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getProductDetails(id);

      setBounty(data);
    } finally {
      setIsLoading(false);
    }
  };

  const seo = useMemo(() => (
    getFormattedSeoInfo({
      seo: shopSettings?.seo?.[SEOType.ProductDescription],
      clientInfo,
      onboardingInfo,
      bounty,
    })
  ), [shopSettings?.seo?.[SEOType.ProductDescription], clientInfo, onboardingInfo, bounty]);

  return (
    <>
      <Helmet>
        <title>{seo?.title || 'Product Details'}</title>
        <meta property="og:title" content={seo?.title || 'Product Details'} />
        <meta name="twitter:title" content={seo?.title || 'Product Details'} />
        <meta name="description" content={seo?.description} />
        <meta property="og:description" content={seo?.description} />
        <meta name="twitter:description" content={seo?.description} />
      </Helmet>
      {kioskMode ? (
        <KioskProductDetails
          bounty={bounty}
          isLoading={isLoading}
        />
      ) : (
        <ProductDetails
          bounty={bounty}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default ProductDetailsPage;
