import { ProductSortOptions } from 'constants/enums';

export const PAYMENT_METHODS_OPTIONS = [
  {
    key: 'CASH',
    value: 'Cash',
  },
  {
    key: 'DEBIT',
    value: 'Debit',
  },
  {
    key: 'BILLMYBANK',
    value: 'Bill My Bank',
  },
  {
    key: 'CHARGEE',
    value: 'Chargee',
  },
];

export const DELIVERY_METHODS_OPTIONS = [
  {
    key: 'PICKUP',
    value: 'In Store Pickup',
  },
  {
    key: 'DELIVERY',
    value: 'Delivery',
  },
  {
    key: 'CURBSIDE',
    value: 'Curbside Pickup',
  },
  // {
  //   key: 'RETAIL',
  //   value: 'Retail',
  //   disabled: true,
  // },
];

export const USE_TYPE_OPTIONS = [
  {
    key: 'is_adult_use',
    value: 'Recreational',
  },
  {
    key: 'is_medical_use',
    value: 'Medical',
  },
];

export const PRODUCT_SORT_OPTIONS = [
  {
    value: 'Featured',
    key: ProductSortOptions.Featured,
  },
  {
    value: 'Price: Low to high',
    key: ProductSortOptions.PriceAsc,
  },
  {
    value: 'Price: High to low',
    key: ProductSortOptions.PriceDesc,
  },
  {
    value: 'Name: A to Z',
    key: ProductSortOptions.NameAsc,
  },
  {
    value: 'Name: Z to A',
    key: ProductSortOptions.NameDesc,
  },
  {
    value: 'Brand: A to Z',
    key: ProductSortOptions.ManufacturerAsc,
  },
  {
    value: 'Brand: Z to A',
    key: ProductSortOptions.ManufacturerDesc,
  },
  {
    value: 'Weight: Low to high',
    key: ProductSortOptions.WeightAsc,
  },
  {
    value: 'Weight: High to low',
    key: ProductSortOptions.WeightDesc,
  },
  {
    value: 'Popularity',
    key: ProductSortOptions.Popularity,
  },
];
