import { Box, Grid, Container } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import BackButton from 'components/BackButton';
import BackCard from 'components/BackCard';
import CartTotal from 'components/CartTotal';
import Coupon from 'components/Coupon';
import CustomButton from 'components/CustomButton';
import EmptyPreviewCart from 'components/EmptyPreviewCart';
import FailedSummaryMessage from 'components/FailedSummaryMessage';
import OrderDisclaimerMessage from 'components/OrderDisclaimerMessage';
import OrderWarningMessage from 'components/OrderWarningMessage';
import ProductMedicalBalanceInfo from 'components/ProductMedicalBalanceInfo';
import SectionTitle from 'components/SectionTitle';
import SubheaderWrapper from 'components/SubheaderWrapper';
import { StyledSummary, StyledSummaryPrice } from 'containers/CartListingPage/index.styled';
import CartProduct from 'containers/CartProduct';
import MedicalBalance from 'containers/MedicalBalance';
import { CartContext } from 'context/CartContext';
import { SystemContext } from 'context/SystemContext';
import { UserContext } from 'context/UserContext';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useKiosk } from 'hooks/useKiosk';
import { getCheckoutRoute, getHomeRoute } from 'hooks/useRouting';
import useSummary from 'hooks/useSummary';

import { reportAlert } from 'utils/utils';

const CartListing = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { shopSettings } = useContext(SystemContext);
  const cart = useContext(CartContext);
  const { paymentAccount } = useContext(UserContext);
  const [hasSummaryFailed, setHasSummaryFailed] = useState<boolean>(false);
  const {
    checkSummary,
    setIsDisabled,
    formattedCartDetails,
    isDisabled,
    updatedProducts,
    cartPriceDetails,
    isLoading: isSummaryLoading,
  } = useSummary();
  const { handleTrackViewCart } = useGoogleAnalytics();
  const { kioskMode } = useKiosk();

  const [adjustedProducts, setAdjustedProducts] = useState([] as string[]);

  const isFromCheckout = search.includes('checkout');
  const products = useMemo(() => Object.values(cart?.products) || [], [cart?.products]);
  const showMedicalBalance = !hasSummaryFailed && !isSummaryLoading
    && (!paymentAccount?.alleavesCustomerId || formattedCartDetails.balances);

  useDocumentTitle({ title: 'Cart summary' });

  useEffect(() => {
    if (!isEmpty(cart?.products)) {
      verifySummary();
    }
  }, [cart?.products]);

  const verifySummary = async () => {
    try {
      await checkSummary();
      setHasSummaryFailed(false);
    } catch (e: any) {
      setHasSummaryFailed(true);
      reportAlert(`summary failed: ${JSON.stringify(e)}`);
    }
  };
  useEffect(() => {
    if (!isEmpty(cartPriceDetails)) {
      handleTrackViewCart(cartPriceDetails, products);
    }
  }, [cartPriceDetails]);

  useEffect(() => {
    if (adjustedProducts.length > 0 && adjustedProducts.length === Object.keys(updatedProducts).length) {
      setIsDisabled(false);
    }
  }, [adjustedProducts]);

  const handleRedirectCheckout = () => {
    navigate(getCheckoutRoute());
  };

  const handleChangeQuantity = (productId: string) => {
    if (updatedProducts?.[productId] && !adjustedProducts.includes(productId)) {
      setAdjustedProducts([...adjustedProducts, productId]);
    }
  };

  const handleBack = () => {
    if (isFromCheckout) {
      navigate(getHomeRoute());
    } else {
      navigate(-1);
    }
  };

  return (
    <Box height="100%">
      {kioskMode ? (
        <Container>
          <BackCard onClick={handleBack} text="Back" sx={{ width: '300px' }} />
        </Container>
      ) : (
        <SubheaderWrapper>
          <Box display="flex" alignItems="center">
            <BackButton href={isFromCheckout ? getHomeRoute() : ''} label="Back" />
            <SectionTitle variant="subheader" title="Your cart" sx={{ ml: 2.5 }} />
          </Box>
        </SubheaderWrapper>
      )}

      <Container>
        {products?.length > 0
          ? (
            <Grid container spacing={5} alignItems="stretch">
              <Grid
                item
                xs={12}
                md={kioskMode ? 6.5 : 7.5}
                lg={kioskMode ? 7 : 8}
              >
                {products?.map((product, index) => {
                  const wasAdjusted = adjustedProducts?.includes(product.id);
                  const isProductUpdated = !isEmpty(updatedProducts?.[product?.id] || {});
                  const newRawQuantity = updatedProducts?.[product?.id]?.quantity || 0;
                  const updatedQuantity = !Number.isNaN(newRawQuantity) ? Math.max(0, newRawQuantity) : 0;
                  const { balances = {} } = product;

                  return (
                    <Box mb={index + 1 !== products?.length ? 1.5 : 0} key={product?.id}>
                      <CartProduct
                        product={product}
                        isProductUpdated={isProductUpdated}
                        updatedQuantity={updatedQuantity}
                        handleChange={handleChangeQuantity}
                        wasAdjusted={wasAdjusted}
                      />

                      {balances && Object.values(balances).map((balance) => (
                        <ProductMedicalBalanceInfo balance={balance} key={balance?.key} />
                      ))}
                    </Box>
                  );
                })}

                {shopSettings?.orderWarningMessage && (
                  <Box mt={2.5}>
                    <OrderWarningMessage message={shopSettings?.orderWarningMessage} />
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={kioskMode ? 5.5 : 4.5}
                lg={kioskMode ? 5 : 4}
              >
                <StyledSummary isKiosk={kioskMode}>
                  <StyledSummaryPrice>
                    <CartTotal
                      cartPriceDetails={formattedCartDetails}
                      isLoading={isSummaryLoading}
                      hasFailed={hasSummaryFailed}
                    />
                    <CustomButton
                      id="button-goToCheckout"
                      variant="contained"
                      fullWidth
                      sx={{ mt: 3 }}
                      onClick={handleRedirectCheckout}
                      disabled={isDisabled || hasSummaryFailed}
                    >
                      Go to checkout
                    </CustomButton>

                    {shopSettings?.orderDisclaimerMessage && (
                      <OrderDisclaimerMessage message={shopSettings?.orderDisclaimerMessage} />
                    )}
                  </StyledSummaryPrice>

                  <Box mt={5}>
                    <Coupon onChangeSummary={checkSummary} />
                  </Box>

                  {hasSummaryFailed && <FailedSummaryMessage sx={{ mt: 5 }} />}

                  {showMedicalBalance && !kioskMode && (
                    <Box mt={5}>
                      <MedicalBalance
                        balances={formattedCartDetails.balances}
                        checkSummary={checkSummary}
                      />
                    </Box>
                  )}
                </StyledSummary>
              </Grid>
            </Grid>
          ) : (
            <Box mt={12}>
              <EmptyPreviewCart hasButton fontSize="large" variant="h6" />
            </Box>
          )}
      </Container>
    </Box>
  );
};

export default CartListing;
