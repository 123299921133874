import { PointCurrency } from 'constants/enums';
import { Reward, Price } from 'types/money.interface';
import { Product, ProductSpecificInfo } from 'types/product.interface';
import { formatReward } from 'utils/currencyUtils';
import { isZero } from 'utils/moneyUtils';
import { getPointsAmount } from 'utils/pointUtils';

function isDiscount(initial: Reward|undefined, current: Reward|undefined): boolean {
  if (initial && current) {
    return !(JSON.stringify(initial) === JSON.stringify(current));
  }

  return false;
}

export function hasDiscount(initial: Price|null, current: Price|null): boolean {
  if (initial && current) {
    return isDiscount(initial.mainPrice, current.mainPrice)
      || isDiscount(initial.altPrice1, current.altPrice1)
      || isDiscount(initial.altPrice2, current.altPrice2);
  }

  return false;
}

function hasNoPrice(reward: Reward|undefined): boolean {
  if (!reward) {
    return true;
  }

  return (!reward.money || isZero(reward.money)) && !getPointsAmount(reward, PointCurrency.POINT);
}

export function isFree(price: Price|null): boolean {
  if (!price) {
    return true;
  }

  return hasNoPrice(price.mainPrice) && hasNoPrice(price.altPrice1) && hasNoPrice(price.altPrice2);
}

function hasVariants(variants: { [key: string]: ProductSpecificInfo }): boolean {
  return Boolean(variants && Object.keys(variants).length);
}

function getVariant(variants: { [key: string]: ProductSpecificInfo }, sku: string): ProductSpecificInfo|null {
  if (!sku || !hasVariants(variants)) {
    return null;
  }

  return variants[sku];
}

export function getTopLevelVariant(product: Product): ProductSpecificInfo|null {
  if (!product) {
    return null;
  }

  const { merchantSku, variants, imageUrl } = product;
  const productSpecificInfo = getVariant(variants, merchantSku);

  if (productSpecificInfo && !productSpecificInfo?.sku) {
    productSpecificInfo.sku = merchantSku;
  }

  if (productSpecificInfo && !productSpecificInfo?.imageUrl) {
    productSpecificInfo.imageUrl = imageUrl;
  }

  return productSpecificInfo;
}

export function getPrices(product: Product): { msrp: Price|null; price: Price|null } {
  if (!product) {
    return {
      msrp: null,
      price: null,
    };
  }

  const productInfo = getTopLevelVariant(product);

  return {
    msrp: productInfo?.msrp || null,
    price: productInfo?.price || null,
  };
}

export function formatPrice(price: Price|null) {
  if (!price) {
    return '';
  }

  const parts = [];

  if (price.mainPrice && Object.keys(price.mainPrice).length) {
    const reward = formatReward(price.mainPrice, '0');

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice1 && Object.keys(price.altPrice1).length) {
    const reward = formatReward(price.altPrice1, '0');

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice2 && Object.keys(price.altPrice2).length) {
    const reward = formatReward(price.altPrice2, '0');

    if (reward) {
      parts.push(reward);
    }
  }

  return parts.join(' / ');
}
