import {
  createContext, FC, ReactNode, useContext, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';

import { LocationContext } from 'context/LocationContext';

import { Bounty } from 'types/bounty.interface';
import { Cart } from 'types/cart.interface';
import { formatProductForCart, getStoreCartKey } from 'utils/cartUtils';
import { getTopLevelVariant } from 'utils/priceUtils';
import { getRealQuantity } from 'utils/productUtils';
import { deleteCartFromStorage, getCartFromStorage, saveCartToStorage } from 'utils/storageUtils';
import { reportAlert } from 'utils/utils';

const CartContext = createContext({
  products: {},
} as Cart);

const CartDispatchContext = createContext({
  removeItem: (productId: string) => {}, // eslint-disable-line
  addItem: (bounty: Bounty, quantity: number, hideSuccessNotification?: boolean) => {}, // eslint-disable-line
  setQuantity: (productId: string, quantity: number) => {}, // eslint-disable-line
  setItems: (bounties: Bounty[]) => {}, // eslint-disable-line
  removeItems: () => {}, // eslint-disable-line
});

interface CartProviderProps {
  children: ReactNode;
}

const CartProvider:FC<CartProviderProps> = ({ children }) => {
  const [cartDetails, setCartDetails] = useState<Cart>({ products: {} } as Cart);
  const storeKey = getStoreCartKey();
  const { onboardingInfo } = useContext(LocationContext);

  useEffect(() => {
    if (onboardingInfo?.storeId && onboardingInfo?.useType) {
      const savedCart = getCartFromStorage() || {};
      const storeCart = savedCart?.[storeKey] || { products: {} } as Cart;

      if (!savedCart?.[storeKey]) {
        saveCartToStorage({ ...savedCart, [storeKey]: storeCart });
      }
      setCartDetails(storeCart);
    }
  }, [onboardingInfo?.storeId, onboardingInfo?.useType]);

  const saveCart = (cart:Cart): void => {
    const savedCart = getCartFromStorage();

    setCartDetails(cart);
    saveCartToStorage({ ...savedCart, [storeKey]: cart });
  };

  const removeItem = (productId: string): void => {
    const cart = JSON.parse(JSON.stringify(cartDetails));
    delete cart?.products?.[productId];
    saveCart(cart);
  };

  const addItem = (bounty: Bounty, quantityToAdd: number, hideSuccessNotification = false) => {
    const { quantity } = getTopLevelVariant(bounty?.product) || {};
    const productQuantity = Math.max(0, quantity || 0);
    const availableQuantity = getRealQuantity(quantity, bounty.id, cartDetails);

    if (productQuantity <= 0) {
      return toast.info('Product is out of stock');
    }

    if (availableQuantity <= 0) {
      return toast.info('Maximum available quantity for this item has already been added to cart');
    }

    if (!bounty?.id) {
      reportAlert(`Cannot add product to cart, missing id:\n${JSON.stringify(bounty)}`);
      return toast.info('The product is missing important information, we can\'t add it to the cart.');
    }

    const existingBounty = cartDetails?.products?.[`temp:${bounty.id}`];
    const newQuantity = existingBounty?.quantity ? quantityToAdd + existingBounty.quantity : quantityToAdd;
    const formattedProduct = formatProductForCart(bounty, newQuantity);

    saveCart({
      ...cartDetails,
      products: {
        ...cartDetails?.products,
        [formattedProduct.id]: formattedProduct,
      },
    });

    return !hideSuccessNotification ? toast.success('Product added to cart!') : null;
  };

  const setQuantity = (productId: string, quantity: number): void => {
    saveCart({
      ...cartDetails,
      products: {
        ...cartDetails?.products,
        [productId]: {
          ...cartDetails?.products[productId],
          quantity,
        },
      },
    });
  };

  const setItems = (bounties: Bounty[]): void => {
    let formattedProducts = {};
    let hasBrokenBounties = false;

    bounties.forEach((bounty) => {
      const { quantity } = getTopLevelVariant(bounty?.product) || {};
      const formattedProduct = formatProductForCart(bounty, quantity || 0);

      if (!formattedProduct?.id) {
        hasBrokenBounties = true;
        return;
      }

      formattedProducts = {
        ...formattedProducts,
        [formattedProduct.id]: formattedProduct,
      };
    });

    if (hasBrokenBounties) {
      reportAlert(`Some products have broken bounties, cannot add to cart:\n${JSON.stringify(bounties)}`);
      toast.info('Some products are missing important information, we can\'t add it to the cart.');
    }

    const updatedCart = {
      ...cartDetails,
      products: formattedProducts,
    } as Cart;

    saveCart(updatedCart);
  };

  const removeItems = (): void => {
    deleteCartFromStorage();
    saveCart({ products: {} } as Cart);
  };

  return (
    <CartContext.Provider value={cartDetails}>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <CartDispatchContext.Provider value={{
        removeItem,
        addItem,
        setQuantity,
        setItems,
        removeItems,
      }}
      >
        {children}
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  );
};

export {
  CartProvider,
  CartContext,
  CartDispatchContext,
};
