import { Box, SxProps, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

import CartPrice from 'components/CartPrice';

import { CartPriceDetails } from 'types/cart.interface';

import { CustomTypographyVariant } from 'assets/themes/defaultTheme';

interface CartTotalProps {
  cartPriceDetails: CartPriceDetails;
  isLoading?: boolean;
  hasFailed?: boolean;
  sx?: SxProps;
  fontWeight?: number;
  variant?: Variant | CustomTypographyVariant;
}

const CartTotal: FC<CartTotalProps> = ({
  cartPriceDetails,
  isLoading = false,
  hasFailed = false,
  sx = {},
  fontWeight = 700,
  variant = 'body1',
}) => (
  <Box sx={sx}>
    <Box display="flex" justifyContent="space-between">
      <Typography variant={variant} fontWeight={fontWeight}>Items Total:</Typography>
      <CartPrice variant={variant} price={cartPriceDetails?.itemsTotal} isLoading={isLoading} hasFailed={hasFailed} />
    </Box>
    {cartPriceDetails?.discounts && (
    <Box display="flex" justifyContent="space-between">
      <Typography variant={variant} fontWeight={fontWeight}>Discount:</Typography>
      <CartPrice variant={variant} price={cartPriceDetails?.discounts} isLoading={isLoading} hasFailed={hasFailed} />
    </Box>
    )}
    <Box display="flex" justifyContent="space-between">
      <Typography variant={variant} fontWeight={fontWeight}>Subtotal:</Typography>
      <CartPrice variant={variant} price={cartPriceDetails?.subTotal} isLoading={isLoading} hasFailed={hasFailed} />
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography variant={variant} fontWeight={fontWeight}>Taxes:</Typography>
      <CartPrice variant={variant} price={cartPriceDetails?.taxes} isLoading={isLoading} hasFailed={hasFailed} />
    </Box>
    {cartPriceDetails.roundingAmount && (
    <Box display="flex" justifyContent="space-between">
      <Typography variant={variant} fontWeight={fontWeight}>Rounding:</Typography>
      <CartPrice
        variant={variant}
        price={cartPriceDetails?.roundingAmount}
        isLoading={isLoading}
        hasFailed={hasFailed}
      />
    </Box>
    )}
    <Box display="flex" justifyContent="space-between" mt={2}>
      <Typography variant={variant} fontWeight={fontWeight}>Total:</Typography>
      <CartPrice
        variant={variant}
        price={cartPriceDetails?.formattedTotal}
        isLoading={isLoading}
        hasFailed={hasFailed}
      />
    </Box>
  </Box>
);

export default CartTotal;
