import {
  Box, Divider, Grid, Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { FC } from 'react';

import { getChemicalIcon } from 'components/ChemicalInformation/config';
import { ChemicalElement, ChemicalPercent } from 'components/ChemicalInformation/index.styled';

import { ChemicalInfo } from 'types/product.interface';
import { formatChemicalValue, getChemicalElementPercent } from 'utils/productUtils';

interface ChemicalInformationProps {
  chemicalComposition: ChemicalInfo[];
  sx?: object;
}

const ChemicalInformation:FC<ChemicalInformationProps> = ({ chemicalComposition, sx }) => (
  <>
    <Grid container spacing={1} sx={sx}>
      {chemicalComposition
        .filter((element) => element.name && (isNil(element?.secondary) || element?.secondary))
        .map((element, index) => {
          const elementPercent = getChemicalElementPercent(element);
          const ChemicalIcon = getChemicalIcon(element.iconName);

          return (
            <Grid key={index} item xs={12} sm={6}>
              <ChemicalElement kind={element?.kind}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <ChemicalIcon sx={{ mr: 1 }} />
                    <Typography fontWeight={700} variant="body2">
                      {element.name}
                    </Typography>
                  </Box>
                  {element.value > 0 && (
                    <Typography fontWeight={700} variant="body2">
                      {formatChemicalValue({ element, summary: false })}
                    </Typography>
                  )}
                </Box>

                {element.description && (
                  <Typography ml={4} variant="body2" gutterBottom>
                    {element.description}
                  </Typography>
                )}

                {elementPercent && elementPercent > 0 && (
                  <ChemicalPercent variant="determinate" value={elementPercent} />
                )}
              </ChemicalElement>
            </Grid>
          );
        })}
    </Grid>
    <Divider sx={{ mt: 2 }} />
  </>
);

export default ChemicalInformation;
