import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { Card } from 'containers/ProductDetailsPage/StrainType/index.styled';

import { Tag } from 'types/product.interface';

interface StrainTypeProps {
  strainType: Tag;
}

const StrainType:FC<StrainTypeProps> = ({ strainType }) => (
  <Grid container spacing={1} sx={{ mt: 1 }}>
    <Grid item xs={12} sm={6} md={12} lg={6}>
      <Card>
        <Typography fontWeight={700} variant="body2">
          Strain Type:
          {' '}
          {strainType.displayName}
        </Typography>
      </Card>
    </Grid>
  </Grid>
);

export default StrainType;
