import { Box, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import QuantityButtonsSelector from 'components/QuantityButtonsSelector';
import { CartContext, CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { Bounty } from 'types/bounty.interface';
import { getTopLevelVariant } from 'utils/priceUtils';
import { getRealQuantity } from 'utils/productUtils';

interface KioskQuantitySelectorProps {
  bounty: Bounty;
  setShowConfirmation: () => void;
  isLoading?: boolean;
}

const KioskQuantitySelector:FC<KioskQuantitySelectorProps> = ({ bounty, setShowConfirmation, isLoading }) => {
  const cart = useContext(CartContext);
  const navigate = useNavigate();
  const { addItem } = useContext(CartDispatchContext);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const { product } = bounty;
  const { quantity } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const realQuantity = getRealQuantity(quantity, bounty.id, cart);
  const showRemainingStock = realQuantity > 0 && realQuantity <= 10;

  const { handleTrackAddItemToCart } = useGoogleAnalytics();

  const decreaseQuantity = () => {
    if (isLoading) {
      return;
    }

    setSelectedQuantity((prevQuantity) => (prevQuantity > 1 ? selectedQuantity - 1 : 1));
  };

  const increaseQuantity = () => {
    if (isLoading) {
      return;
    }

    setSelectedQuantity((prevQuantity) => (prevQuantity === realQuantity ? selectedQuantity : selectedQuantity + 1));
  };

  const handleAddToCart = () => {
    addItem(bounty, selectedQuantity, true);
    setSelectedQuantity(1);
    handleTrackAddItemToCart(bounty, quantity);
    setShowConfirmation();
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Box width="100%">
      {productQuantity <= 0 && (
        <Typography variant="body2" color="error" mr={2}>
          Out of stock
        </Typography>
      )}

      {realQuantity > 0 && (
        <QuantityButtonsSelector
          onDecrease={decreaseQuantity}
          onIncrease={increaseQuantity}
          quantity={selectedQuantity}
        />
      )}

      <Box display="flex" gap={1} width="100%" mt={2}>
        <CustomButton
          disabled={isLoading}
          id="button-cancel"
          variant="outlined"
          fullWidth
          onClick={handleCancel}
        >
          Cancel
        </CustomButton>
        <CustomButton
          disabled={isLoading}
          id="button-addProduct"
          fullWidth
          onClick={handleAddToCart}
        >
          Add to Cart
        </CustomButton>
      </Box>

      {showRemainingStock && (
        <Typography variant="body2" fontWeight={600} color="error" mt={2}>
          Only
          {' '}
          {realQuantity}
          {' '}
          left in stock!
        </Typography>
      )}
    </Box>
  );
};

export default KioskQuantitySelector;
