import { CurrencyTypes, PointCurrency } from 'constants/enums';
import { STARS_VALUE } from 'constants/rating';
import { Money, Point, Reward } from 'types/money.interface';
import { getAmountAsBigDecimal, isZero } from 'utils/moneyUtils';
import { isPointZero, sumOfAbs } from 'utils/pointsUtils';
import { getNormalizedPoints, getPointsAmount } from 'utils/pointUtils';
import { hasMoney, hasPoints, isRewardEmpty } from 'utils/rewardUtils';

export function pointCurrencyAsEnum(currency: string): string {
  if (!currency) {
    return PointCurrency.NONE;
  }

  return PointCurrency[currency as keyof typeof PointCurrency] || PointCurrency.UNKNOWN;
}

export function normalizeCurrency(currency: string): string {
  switch (currency) {
    case PointCurrency.STAR2:
    case PointCurrency.STAR3:
      return PointCurrency.STAR;
    default:
      return currency;
  }
}

export function multiplier(currency: string): number {
  return STARS_VALUE[currency] || 1;
}

export function formatMoney(money: Money, formal: boolean | undefined, defaultValue: string, enforceDecimal?: boolean) {
  if (!money || !money.amount) {
    return defaultValue;
  }

  const amountAsDec: string | any = getAmountAsBigDecimal(money.amount, enforceDecimal);

  let amount;
  switch (money.currency) {
    case CurrencyTypes.EUR:
      amount = `€${amountAsDec}`;
      break;
    case CurrencyTypes.GBP:
      amount = `£${amountAsDec}`;
      break;
    case CurrencyTypes.CAD:
      amount = `$${amountAsDec} CAD`;
      break;
    case CurrencyTypes.USD:
      amount = `$${amountAsDec}`;
      break;
    case CurrencyTypes.NONE:
      amount = amountAsDec.toString();
      break;

    default: {
      if (money.currency) {
        amount = `${amountAsDec} ${money.currency}`;
      } else {
        amount = amountAsDec.toString();
      }
    }
  }

  if (!formal) {
    amount = amount.replace('[.]0*$', '');
  }

  return amount;
}

export function formatReward(reward: Reward | undefined, defaultValue: string, enforceDecimal?: boolean) {
  let moneyStr = null;
  let pointStr = null;
  // const voucherStr = null;

  if (!reward) {
    return defaultValue;
  }

  if (reward.money && !isZero(reward.money)) {
    moneyStr = formatMoney(reward.money, false, defaultValue, enforceDecimal);
  }

  if (reward.points && sumOfAbs(reward.points) > 0) {
    const stars = getNormalizedPoints(reward.points, PointCurrency.STAR);

    if (stars > 0) {
      pointStr = stars === 1 ? 'one star' : `${stars} stars`;
    } else {
      const points = getPointsAmount(reward, PointCurrency.POINT);
      pointStr = points === 1 ? '1 point' : `${points} points`;
    }
  }

  // if (reward.vouchers) {
  //   voucherStr = `1 ${reward.vouchers.gist}`;
  // }

  const arr = [];

  if (moneyStr && moneyStr.trim()) {
    arr.push(moneyStr);
  }

  if (pointStr && pointStr.trim()) {
    arr.push(pointStr);
  }

  // if (voucherStr && voucherStr.trim()) {
  //   arr.push(voucherStr);
  // }

  return arr.join(', ');
}

export function multiply(reward: Reward, quantity: number) {
  let result: Reward = {
    points: undefined,
    money: undefined,
  };

  if (!reward || !quantity) {
    return null;
  }

  if (isRewardEmpty(reward)) {
    return reward;
  }

  if (hasMoney(reward)) {
    result = {
      ...result,
      money: multiplyMoney(reward.money, quantity),
    };
  }
  if (hasPoints(reward)) {
    result = {
      ...result,
      points: multiplyPoints(reward.points, quantity),
    };
  }

  return result;
}

function multiplyMoney(money: Money | undefined, quantity: number) {
  if (!money || !quantity) {
    return undefined;
  }

  return {
    amount: (+money.amount * quantity).toString(),
    currency: money.currency,
  };
}
function multiplyPts(point: Point, quantity: number) {
  if (!point || !quantity) {
    return null;
  }

  if (isPointZero(point)) {
    return point;
  }

  return {
    amount: Number(point.amount) * quantity,
    currency: point.currency,
  };
}

function multiplyPoints(points: Record<string, Point> | undefined, quantity: number) {
  let result = {};

  if (!points || !quantity) {
    return undefined;
  }

  if (!Object.keys(points).length) {
    return points;
  }

  Object.keys(points).forEach((pointType) => {
    result = {
      ...result,
      [pointType]: multiplyPts(points[pointType], quantity),
    };
  });

  return result;
}
