import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Box, Tooltip, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FC, MouseEvent, useMemo } from 'react';

import Brand from 'components/Brand';
import Dot from 'components/Dot';
import FreeTag from 'components/FreeTag';
import {
  StyledSection, StyledImage, StyledPriceBox, StyledAddToCard,
} from 'components/ProductCard/index.styled';
import ProductPrice from 'components/ProductPrice';
import ProductWeight from 'components/ProductWeight';

import { DASHBOARD_PRODUCT_COVER_SIZE } from 'constants/cover';
import { Bounty } from 'types/bounty.interface';
import { getProductCover } from 'utils/attachmentsUtils';
import { textTruncate } from 'utils/formatters';
import { getPrices, getTopLevelVariant, isFree } from 'utils/priceUtils';
import {
  filterChemicals, formatChemicalValue, getManufacturer, getProductStrainType,
} from 'utils/productUtils';
import { getPublicUrlForImage } from 'utils/publicUrl';
import { reportAlert } from 'utils/utils';

interface ProductCardProps {
  bounty: Bounty;
  onAdd?: (bounty: Bounty) => unknown;
  hasAddButton?: boolean;
  isBrandDisabled?: boolean;
  isDescriptionVisible?: boolean;
}

const ProductCard:FC<ProductCardProps> = ({
  bounty,
  onAdd,
  hasAddButton = false,
  isBrandDisabled = false,
  isDescriptionVisible = false,
}) => {
  const { product, tags } = bounty;
  const { price, msrp } = getPrices(product);
  const isProductFree = isFree(price);
  const manufacturer = getManufacturer(product);
  const { quantity } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const isOutOfStock = productQuantity <= 0;
  const coverUrl = useMemo(() => {
    if (bounty.id && !product) {
      reportAlert(`Bounty without product: ${bounty.id}`);
      return '';
    }

    const cover = getProductCover(
      product.imageUrl,
      DASHBOARD_PRODUCT_COVER_SIZE.w,
      DASHBOARD_PRODUCT_COVER_SIZE.h,
      product.imageCdn,
    );
    return cover.cdnUrl || cover.url;
  }, [bounty.id]);
  const strainType = tags?.tags ? getProductStrainType(tags?.tags) : null;
  const chemicalCompositionString = useMemo(() => {
    if (!product?.chemicalComposition) {
      return '';
    }

    const availableChemicals = filterChemicals(product.chemicalComposition) || [];
    return availableChemicals
      .filter((element) => {
        const hasChemical = isNil(element.summary) || element.summary;
        return element.value > 0 && element.name && hasChemical;
      })
      .map((element) => `${element.name} ${formatChemicalValue({ element, summary: true })}`)
      .join(', ');
  }, [product?.chemicalComposition]);

  const addToCart = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (onAdd) {
      onAdd(bounty);
    }
  };

  return (
    <StyledSection>
      <Box margin="0 auto" height={150}>
        <StyledImage
          component="img"
          src={coverUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
          alt={product?.name}
        />
      </Box>

      <Box my={1}>
        {!!product?.name && (
          <Typography fontWeight="700" gutterBottom>
            {product?.name}
          </Typography>
        )}
        {!!manufacturer && (
          <Brand brand={manufacturer} disabled={isBrandDisabled} />
        )}
        {product?.description && isDescriptionVisible && (
          <Typography color="textSecondary" variant="body2" my={1}>
            {textTruncate(product?.description, 50)}
          </Typography>
        )}
        {(strainType || chemicalCompositionString) && (
          <Typography component="div" color="textSecondary" variant="body2" my={1}>
            {!!strainType && strainType.displayName}
            {!!strainType && !!chemicalCompositionString && <Dot display="inline-block" mb={0.5} mx={1} />}
            {!!chemicalCompositionString && chemicalCompositionString}
          </Typography>
        )}
      </Box>

      <StyledPriceBox>
        <Box display="flex" alignItems="center" gap={1}>
          {isProductFree
            ? <FreeTag fontWeight={700} variant="body2" />
            : <ProductPrice price={price} msrp={msrp} fontWeight={700} variant="body1" />}
          {product.sortWeight && (
            <>
              <Dot />
              <ProductWeight fontWeight={700} variant="body1" weight={product.sortWeight} />
            </>
          )}
        </Box>
        {hasAddButton && (
          <Box>
            <Tooltip title={isOutOfStock ? 'Out of stock' : 'Add to cart'}>
              <span>
                <StyledAddToCard id="iconButton-addToCard" onClick={addToCart} disabled={isOutOfStock}>
                  <AddShoppingCartIcon fontSize="small" />
                </StyledAddToCard>
              </span>
            </Tooltip>
          </Box>
        )}
      </StyledPriceBox>
    </StyledSection>
  );
};

export default ProductCard;
