import { Box, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC } from 'react';

import Brand from 'components/Brand';
import {
  PriceText, PriceWrapper, QuantityText, Root, StyledImage,
} from 'components/OrderProductPreview/index.styled';

import { ProductInfo } from 'types/cart.interface';
import { formatReward } from 'utils/currencyUtils';
import { textTruncate } from 'utils/formatters';
import { getPublicUrlForImage } from 'utils/publicUrl';

interface OrderProductPreviewProps {
  product: ProductInfo;
}

const OrderProductPreview: FC<OrderProductPreviewProps> = ({ product }) => {
  const { price, quantity, manufacturer } = product;
  const theme = useTheme();
  const formattedName = textTruncate(product?.name, 30);
  const formattedPrice = formatReward(price, '0', true);
  const matchesScreenSizeSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Root>
      <StyledImage
        src={product?.imageUrl || getPublicUrlForImage('images/placeholder_image.jpg')}
        alt={product?.name}
      />

      <PriceWrapper>
        <Box ml={matchesScreenSizeSm ? 0 : 1}>
          <Typography variant="body2" fontWeight={700} noWrap mb={0.5}>
            {matchesScreenSizeSm ? formattedName : product?.name}
          </Typography>

          {!!manufacturer && (
            <Brand brand={manufacturer} />
          )}
        </Box>

        <Box>
          <PriceText variant="body1" fontWeight={700}>
            {formattedPrice}
          </PriceText>

          <QuantityText variant="body2">
            {`X ${quantity}`}
          </QuantityText>
        </Box>
      </PriceWrapper>
    </Root>
  );
};

export default OrderProductPreview;
