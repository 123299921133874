import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Box, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';

import CustomButton from 'components/CustomButton';
import QuantitySelector from 'components/QuantitySelector';
import { CartContext, CartDispatchContext } from 'context/CartContext';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

import { Bounty } from 'types/bounty.interface';
import { getTopLevelVariant } from 'utils/priceUtils';
import { getRealQuantity } from 'utils/productUtils';

interface ProductQuantitySelectorProps {
  bounty: Bounty;
}

const ProductQuantitySelector:FC<ProductQuantitySelectorProps> = ({ bounty }) => {
  const cart = useContext(CartContext);
  const { addItem } = useContext(CartDispatchContext);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const { product } = bounty;
  const { quantity } = getTopLevelVariant(product) || {};
  const productQuantity = Math.max(0, quantity || 0);
  const realQuantity = getRealQuantity(quantity, bounty.id, cart);
  const showRemainingStock = realQuantity > 0 && realQuantity <= 10;

  const { handleTrackAddItemToCart } = useGoogleAnalytics();

  const handleQuantityChanges = (newQuantity: number) => {
    setSelectedQuantity(newQuantity);
  };

  const handleAddToCart = () => {
    addItem(bounty, selectedQuantity);
    setSelectedQuantity(1);
    handleTrackAddItemToCart(bounty, quantity);
  };

  return (
    <div>
      <Box display="flex" alignItems="center">
        {productQuantity <= 0 && (
          <Typography variant="body2" color="error" mr={2}>
            Out of stock
          </Typography>
        )}

        {realQuantity > 0 && (
          <Box mr={2}>
            <QuantitySelector
              selectedQuantity={selectedQuantity}
              handleQuantity={handleQuantityChanges}
              availableQuantity={realQuantity}
            />
          </Box>
        )}

        <CustomButton
          id="button-addToCart"
          variant="contained"
          onClick={handleAddToCart}
          startIcon={<AddShoppingCartIcon />}
          disabled={productQuantity <= 0}
        >
          Add to cart
        </CustomButton>
      </Box>

      {showRemainingStock && (
        <Typography variant="body2" fontWeight={600} color="error" mt={2}>
          Only
          {' '}
          {realQuantity}
          {' '}
          left in stock!
        </Typography>
      )}
    </div>
  );
};

export default ProductQuantitySelector;
